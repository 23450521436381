<template>
  <div class="common-bg team">
    <van-nav-bar
      title="商品管理"
      left-arrow
      :fixed="true"
      right-text="添加商品"
      :placeholder="true"
      @click-left="getBack"
      @click-right="handleAddGood"
    />
    <div class="padded-15">
      <div class="box" v-for="(item, index) in goodsList" :key="index">
        <img :src="item.shop_home_img" class="img" />
        <div class="good-content">
          <div style="font-weight: bold">{{ item.trade_name }}</div>
          <p style="margin: 20px">¥{{ item.trade_price }}</p>
          <div class="btns">
            <van-button
              plain
              size="small"
              type="primary"
              @click="goodEdit(item)"
              >编辑</van-button
            >
            <van-button
              plain
              size="small"
              type="primary"
              @click="handleDelete(item)"
              >删除</van-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsList, deleteGood } from "@/utils/api.js";
import { Toast } from "vant";
export default {
  name: "team",
  data() {
    return {
      goodsManage: {
        shop_home_img: [], //商品首页图
        trade_price: "", //商品价格
        trade_name: "", //商品名称
      },
      page: 12,
      page_size: 10,
      goodsList: {},
    };
  },
  methods: {
    initData() {
      getGoodsList({ page: this.page, page_size: this.page_size }).then(
        (res) => {
          if (res.code === 200) {
            this.goodsList = res.data;
          }
        }
      );
    },
    getBack() {
      this.$router.go(-1);
    },
    // 添加商品
    handleAddGood() {
      this.$router.push("/user/addGood");
    },
    goodEdit(item) {
      console.log(item);
      this.$router.push({
        name: "goodEdit",
        path: "/user/goodEdit",
        query: {
          id: item.id,
          img: item.shop_home_img,
          name: item.trade_name,
          price: item.trade_price,
        },
      });
    },
    handleDelete(item) {
      console.log(item);
      deleteGood({ id: item.id }).then((res) => {
        if (res.code === 200) {
          Toast.success("删除成功");
        }
      });
    },
  },
  created() {
    this.initData();
    const arr = [1, 2, 3, 4, 5, 6];
    console.log(arr.join(","), "数组转字符串");
  },
};
</script>

<style lang="scss" scoped>
.team {
  .box {
    height: 200px;
    background: hsla(0, 4%, 78%, 0.7);
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    align-items: center;

    .img {
      width: 120px;
      height: 120px;
    }

    .good-content {
      width: 200px;
      text-align: center;

      .btns {
        display: flex;
        justify-content: space-around;

        .van-button {
          border: none;
          background: none;
        }
      }
    }
  }
}
</style>
